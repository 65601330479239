<template>
    <div class="action-more-profile m-b-16">
        <template>
            <div class="message">
                <div class="message-top">
                    <i class="material-icons c-primary">grade</i>
                    <span>프로필 더 받기 사용!</span>
                </div>
                <div class="content">
                    <p class="m-b-8">
                        프로필 1명 더 받기 기능을 요청했습니다. 매니저가 확인하는 대로 프로필을 전달 드리겠습니다.
                    </p>
                    <p>추가로 여러명을 더 받고 싶으시다면 받고자 하는 명수대로 요청 부탁드립니다!</p>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { isStackRoute } from '@/router/stack-router'

export default {
    name: 'ActionMoreProfile',
    props: ['message'],
    computed: {
        chat() {
            return this.$store.getters.chat
        },
        content() {
            return this.$mustParse(this.message.content) || {}
        },
        title() {
            const title = this.content.title.split('.')
            return `${title[0]}. <br> ${title[1]}`
        },
    },
    methods: {
        async onClickCta() {
            if (!this.content.action) return

            if (this.content.action.includes('http://') || this.content.action.includes('https://')) {
                this.$nativeBridge.postMessage({
                    action: 'openInAppBrowser',
                    value: this.content.action,
                })
                return
            }

            isStackRoute(this.content.action)
                ? this.$stackRouter.push({ name: this.content.action })
                : this.$router.push({ name: this.content.action })
        },
    },
}
</script>

<style lang="scss" scoped>
.action-more-profile {
    padding: 0 12px;
    .message {
        font-size: 14px;
        line-height: 1.57;
        text-align: left;
        color: black;
        @include spoqa-f-regular;

        .message-top {
            @include spoqa-f-medium;
            display: flex;
            align-items: center;
            width: 100%;
            font-size: 16px;
            margin-bottom: 16px;

            i {
                color: $blue-primary;
            }
        }
    }
}
</style>
